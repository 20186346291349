import React from 'react';

const Speakers = () => {
  return (
    <div className="p-6">
      <h1 className="text-blue-600 text-4xl font-bold text-center mb-6">Keynote Speakers</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Dr. C. Mohan</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_c_mohan.jpg" 
            alt="Dr. C. Mohan" 
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p>
              Dr. C. Mohan is an IBM Fellow (Retd) at the IBM Almaden Research Center in San Jose, California, and a visiting professor at China's Tsinghua University. He is renowned for his pioneering work in database management systems and distributed computing.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Dr. Avoki M. Omekanda</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_avoki_omekanda.jpg" 
            alt="Dr. Avoki M. Omekanda" 
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p>
              Dr. Avoki M. Omekanda, a Fellow of IEEE, Vice-President IEEE IAS, is a Research Engineer at the General Motors Global Research & Development Center in Michigan, USA. His research focuses on automotive technologies, and he has contributed significantly to the advancement of electric and autonomous vehicles.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Prof. Shui Yu</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_shui_yu.jpg" 
            alt="Prof. Shui Yu" 
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p>
              Prof. Shui Yu is a Professor at the School of Computer Science, University of Technology Sydney, Australia. His research interests include big data, cybersecurity, and network science. He is a prominent figure in the field of computer science, having authored numerous influential papers.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Prof. K. Gopakumar</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_gopakumar.jpg" 
            alt="Prof. K. Gopakumar" 
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p>
              Prof. K. Gopakumar is an Emeritus Professor at IISc Bangalore. His expertise lies in power electronics and motor drives, and he has been instrumental in developing innovative solutions in the field of electrical engineering.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Dr. Ram Bilas Pachori</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_ram_bilas_pachori.jpg" 
            alt="Dr. Ram Bilas Pachori" 
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p>
              Dr. Ram Bilas Pachori is a Professor at IIT Indore, known for his work in biomedical signal processing and analysis. His research has led to significant advancements in medical technology, particularly in the area of brain-computer interfaces.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Dr. John Jose</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white flex items-center">
          <img 
            src="/images/path_to_image_of_john_jose.jpg" 
            alt="Dr. John Jose" 
            className="w-32 h-32 rounded-full object-cover mr-6" 
          />
          <div>
            <p>
              Dr. John Jose is a Professor at IIT Guwahati. His areas of research include computer architecture and network-on-chip systems. He is recognized for his contributions to improving the performance and efficiency of modern computing systems.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Speakers;