import React, { useState, useEffect } from 'react';

const Carousel = () => {
  const calculateTimeLeft = () => {
    const eventDate = new Date('2025-02-07T00:00:00').getTime();
    const now = new Date().getTime();
    const difference = eventDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const svgStyle = {
    animation: 'moveGraphic 20s ease-in-out infinite',
    position: 'absolute',
    top: 0,
    left: '-20%', // Adjust position to the left
    width: '140vw', // Adjust width to make the graphic larger
    height: '140vh', // Adjust height to make the graphic larger
    objectFit: 'cover',
    transformOrigin: 'center center',
    transform: 'scale(1.3)', // Scale up the SVG elements
  };

  const animationKeyframes = `
    @keyframes moveGraphic {
      0% {
        transform: translateX(0) scale(1.3);
      }
      50% {
        transform: translateX(-5%) scale(1.3); // Decrease movement span
      }
      100% {
        transform: translateX(0) scale(1.3);
      }
    }
  `;

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <style>{animationKeyframes}</style> {/* Inline keyframes */}

      {/* Vector Graphic */}
      <svg
        viewBox="0 0 1600 900"
        xmlns="http://www.w3.org/2000/svg"
        style={svgStyle}  // Apply inline styles
      >
        <path
          d="M0 450C300 250 600 50 900 250C1200 450 1500 250 1800 450V900H0V450Z"
          fill="#1E3A8A"
        />
        <path
          d="M0 450C300 150 600 -50 900 150C1200 350 1500 150 1800 250V900H0V450Z"
          fill="#2563EB"
          opacity="0.7"
        />
        <path
          d="M100 450C400 200 700 0 1000 200C1300 400 1600 200 1900 450V900H100V450Z"
          fill="#3B82F6"
          opacity="0.5"
        />
        <circle cx="900" cy="450" r="360" fill="#BFDBFE" opacity="0.3"/>
      </svg>

      <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-center">
        <div className="text-white relative z-10">
          <h1 className="text-xl md:text-5xl font-bold">
            International Conference on <br/> Emerging Technologies for Intelligent Systems <br/> (ETIS 2025)
          </h1>
          <p className="mt-4 text-xl font-semibold md:text-2xl">
            Dates: 7,8,9 February 2025<br/> Venue: Mar Baselios College of Engineering And Technology, Trivandrum
          </p>
        </div>
      </div>

      {/* Countdown Timer */}
      <div className="absolute bottom-0 left-0 w-full py-6 bg-black bg-opacity-75 text-white text-center z-10">
        <h2 className="text-lg md:text-2xl font-medium mb-4">Countdown to ETIS 2025</h2>
        <div className="flex justify-center space-x-4 md:space-x-8">
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.days || '0'}</span>
            <p className="text-xs md:text-sm">Days</p>
          </div>
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.hours || '0'}</span>
            <p className="text-xs md:text-sm">Hours</p>
          </div>
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.minutes || '0'}</span>
            <p className="text-xs md:text-sm">Minutes</p>
          </div>
          <div>
            <span className="text-xl md:text-3xl font-medium">{timeLeft.seconds || '0'}</span>
            <p className="text-xs md:text-sm">Seconds</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
