import React from 'react';

const EventDetails = () => {
  return (
    <div className="p-6">
      <h1 className="text-blue-600 text-4xl font-bold text-center mb-6">Event Details</h1>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">About the Conference</h2>
        <p className="mb-4">
          ETIS aims to bring together leading academicians, scientists, researchers, and research scholars to exchange and share their experiences.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Who Can Attend?</h2>
        <p className="mb-4">
          Research scholars, UG & PG students, academicians, industry professionals, etc.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Focus Areas</h2>
        <p className="mb-4">
          Technical papers are solicited on subjects pertaining to the scope of the conference, which includes relevant topics.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Topics</h2>
        <ul className="list-disc pl-5 space-y-2">
          <li>Artificial Intelligence and Machine Learning</li>
          <li>Advanced Communication Systems</li>
          <li>IoT Innovations and Smart Technologies</li>
          <li>Robotics and Automation</li>
          <li>Healthcare Technologies</li>
          <li>Intelligent Systems for Power Conversion</li>
          <li>Cybersecurity and Ethical Hacking</li>
          <li>Cloud and Edge Computing Paradigms</li>
          <li>Data Science and Analytics</li>
          <li>Intelligent Transportation Electrification Systems</li>
          <li>Smart Grid and Smart Cities</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Expected Number of Attendees</h2>
        <p className="mb-4">
          The expected number of attendees for the conference is 300, which includes 200 authors and 100 non-author delegates.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Paper Submission</h2>
        <p className="mb-4">
          Prospective authors have to submit the original research works in standard formats like IEEE conference templates for review.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">Tutorials and Keynotes</h2>
        <p className="mb-4">
          Tutorials and keynote talks on relevant topics will be arranged. Experts from India and abroad will be identified for delivering the tutorials and keynotes.
        </p>
      </section>
    </div>
  );
};

export default EventDetails;
