import React from 'react';

const Committee = () => {
  return (
    <div className="p-6 text-center"> {/* Add text-center class here */}
      <h1 className="text-blue-600 text-4xl font-bold mb-6">Committees</h1>

      {/* Advisory & Steering Committee */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-2">Advisory & Steering Committee</h2>

        <div className="mb-4">
          <h3 className="text-xl text-blue-600 font-semibold mb-2">Patron</h3>
          <p>Dr. R. Bindu, Hon’ble Minister for Higher Education, Govt. of Kerala</p>
        </div>

        <div className="mb-4">
          <h3 className="text-xl text-blue-600 font-semibold mb-2">Chairman</h3>
          <p>Dr. Saji Gopinath, Hon’ble Vice-Chancellor, APJAKTU</p>
        </div>

        <div className="mb-4">
          <h3 className="text-xl text-blue-600 font-semibold mb-2">Executive Members</h3>
          <ul className="pl-5">
            <li>Dr. P.K. Biju, Syndicate member, APJAKTU</li>
            <li>Adv. I. Saju, Syndicate member, APJAKTU</li>
            <li>Dr. Jamuna B S, Syndicate member, APJAKTU</li>
            <li>Prof. Sanjeev G, Syndicate member, APJAKTU</li>
            <li>Dr. Vinodhkumar Jacob, Syndicate member, APJAKTU</li>
            <li>Mr. Ashiq Ibrahimkutty, Syndicate member, APJAKTU</li>
            <li>Dr. Venugopal G, BoG member, APJAKTU</li>
            <li>Dr. A. Praveen, Registrar, APJAKTU</li>
            <li>Dr.Shalij PR, Former Dean Research, APJAKTU</li>
            <li>Dr.Vinu Thomas, Dean Academics, APJAKTU</li>
            <li>Dr.Ananda Resmi, Controller of Examinations, APJAKTU</li>
          </ul>
        </div>
      </section>

      {/* Organizing Committee */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">Organizing Committee</h2>
        <p>Dr. Saji Gopinath, Hon’ble Vice-Chancellor, APJAKTU, Chairman</p>
        <p>Dr. Praveen A, Registrar, APJAKTU, Vice-Chairman</p>
        <p>Dr. Vinodkumar Jacob, Member, Syndicate, APJAKTU, Convenor</p>
        <p className="text-xl text-blue-600 font-semibold mb-2">Executive Members</p>
        <p>Dr. P.K. Biju, Member, Syndicate, APJAKTU</p>
        <p>Adv. I. Saju, Member, Syndicate, APJAKTU, Chair, Programme & Sponsorship Committee</p>
        <p>Dr. Jamuna B S, Member, Syndicate, APJAKTU, Chair, Invitation and Registration Committee</p>
        <p>Prof. Sanjeev G, Member, Syndicate, APJAKTU, Chair, Publicity Committee</p>
        <p>Mr. Ashiq Ibrahimkutty, Member, Syndicate, APJAKTU, Co-Chair, Sponsorship Committee</p>
        <p>Dr. Venugopal G, Member BoG, APJAKTU, General Chair</p>
        <p>Dr. Shalij P R, Director, DTE</p>
        <p>Dr. Vinu Thomas, Dean (Academics), APJAKTU, Publication Chair</p>
        <p>Dr. Ananda Reshmi, Controller of Examinations, APJAKTU, Hospitality Chair</p>
        <p>Mr. Aji Kurian Finance Officer, APJAKTU, Finance Chair</p>
        <p>Dr. Satheesh Kumar C, Principal, SCTCE Trivandrum</p>
        <p>Dr. Biju K, Assistant Director (Research), APJAKTU, Organizing Secretary</p>
      </section>

      {/* Technical Program Committee */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-2">Composition of the Technical Programme Committee</h2>

        <p className="font-semibold">Chair</p>
        <p>Dr. Satheesh Kumar C, Principal, SCTCE Trivandrum</p>

        <p className="font-semibold mt-4">Co-Chairs</p>
        <p>Dr. Libish M, Director (Academics), APJAKTU</p>
        <p>Dr. Bejoy Abraham, Joint Director (Academics), APJAKTU</p>

        <p className="font-semibold text-xl text-blue-600 mt-4">Members</p>

        <p className="font-semibold mt-4">EEE</p>
        <p>Dr. Jayaprakash P, GEC Kannur (Chair)</p>
        <p>Dr. Jaison Mathew, GEC Thrissur (Co-Chair)</p>
        <p>Dr. Bijuna Kunju, TKMCE</p>
        <p>Dr. Meenakshy K, GEC Thrissur</p>
        <p>Dr. Dinesh Gopinath, GEC Bartonhill</p>
        <p>Dr. Abhilash T Vijayan, RIT</p>

        <p className="font-semibold mt-4">ECE</p>
        <p>Dr. Shiny G, GEC Bartonhill (Chair)</p>
        <p>Dr. David Solomon, RIT</p>
        <p>Dr. Vinod B R, CET</p>
        <p>Dr. Job Chunkath, GEC Thrissur</p>
        <p>Dr. Prathibha Varghese, SNGCE</p>
        <p>Dr. Jayakumari J, MBCET</p>
        <p>Dr. Sidharth Shelly, MACE Kothamangalam</p>

        <p className="font-semibold mt-4">CSE</p>
        <p>Dr. Sumesh Divakaran, CET (Chair)</p>
        <p>Dr. Salim, CET</p>
        <p>Dr. Gilesh M P, GEC Thrissur</p>
        <p>Dr. Binu V P, MEC</p>
        <p>Dr. Varun G Menon, SCMS</p>
        <p>Dr. Elizabeth Issac, MACE Kothamangalam</p>
        <p>Dr. Tessy Mathew, MBCET</p>
        <p>Dr. Prem Sankar, Providence Engineering College Chengannur</p>
      </section>

      {/* International Advisory Committee */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-2">International Advisory Committee</h2>
        <ul className="pl-5">
          <li>Frede Blaabjerg, Aalborg University, Denmark</li>
          <li>Prasad Enjeti, Texas A&M University, USA</li>
          <li>Saifur Rahman, Advanced Research Institute at Virginia Tech, USA</li>
          <li>Sukumar Kamalasadan, University of North Carolina at Charlotte, USA</li>
          <li>Tomy Sebastian, Halla Mechatronics, Bay City, MI, USA</li>
          <li>Dr. Antonino Nocera, University of Pavia, Italy</li>
          <li>Dr. Serena Nicolazzo, University of Milan, Italy</li>
          <li>Dr. Corrado Aaron Visaggio, University of Sannio, Italy</li>
          <li>Jyotika Athavale, Director, Engineering Architecture at Synopsys</li>
          <li>Mohamed Rawidean Mohd Kassim, R&D Manager, Malaysian Institute of Microelectronics, Malaysia</li>
        </ul>
      </section>
    </div>
  );
};

export default Committee;