import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Squash as Hamburger } from 'hamburger-react'; // Import the hamburger-react library

const HeaderWithLogosAndNavbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (isMobileMenuOpen) {
      setIsDropdownOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header>
      <div className="bg-white py-4 px-4 flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center space-x-4 mb-4 md:mb-0">
          <div>
            <a href="https://www.etis-2025.org">
              <img src="/images/logo1.png" alt="Left Logo" className="h-10" />
            </a>
          </div>
          <div className="flex flex-col text-center">
            <p className="font-bold text-lg">APJAKTU ETIS 2025</p>
            <p className="text-xs font-bold">EMERGING TECHNOLOGIES FOR INTELLIGENT SYSTEMS</p>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
          <a href="https://ias.ieee.org/" target="_blank" rel="noopener noreferrer">
            <img src="/images/logo4.svg" alt="Fourth Logo" className="h-12" />
          </a>
          <a href="https://ktu.edu.in" target="_blank" rel="noopener noreferrer">
            <img src="/images/logo2.png" alt="Right Logo 1" className="h-12" />
          </a>
          <a href="https://www.ieee.org" target="_blank" rel="noopener noreferrer">
            <img src="/images/logo3.svg" alt="Right Logo 2" className="h-10" />
          </a>
        </div>
      </div>
      <nav className="bg-blue-600 relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="hidden md:flex w-full justify-between">
                <div className="ml-10 flex items-baseline space-x-4 w-full">
                  <Link to="/" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-sky-300">
                    Home
                  </Link>
                  <Link to="/about" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    About Us
                  </Link>
                  <div className="relative">
                    <button
                      onClick={handleDropdownToggle}
                      className="text-white px-3 py-2 rounded-md text-sm font-medium flex items-center hover:text-cyan-400"
                    >
                      For Authors
                      <ChevronDownIcon className="w-5 h-5 ml-1" />
                    </button>
                    {isDropdownOpen && (
                      <div
                        ref={dropdownRef}
                        className="absolute z-50 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                      >
                        <Link
                          to="/important-dates"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-cyan-400"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          Important Dates
                        </Link>
                        <Link
                          to="/paper-submission"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-cyan-400"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          Paper Submission
                        </Link>
                      </div>
                    )}
                  </div>
                  <Link to="/committee" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Committees
                  </Link>
                  <Link to="/speakers" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Speakers
                  </Link>
                  <Link to="/sponsorship" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Sponsorship
                  </Link>
                  <Link to="/event-details" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Event Details
                  </Link>
                  <Link to="/registration" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Registration
                  </Link>
                  <Link to="/contact" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Contact
                  </Link>
                  <Link to="/announcements" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400">
                    Announcements
                  </Link>
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden relative z-40">
              <Hamburger
                toggled={isMobileMenuOpen}
                toggle={setIsMobileMenuOpen}
                onToggle={handleMobileMenuToggle}
                size={24}
                duration={0.5}
                color="#ffffff"
              />
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        {isMobileMenuOpen && (
          <div className="absolute top-16 left-0 w-full bg-blue-600 text-white z-30">
            <div className="flex flex-col items-center space-y-4 py-4">
              <Link
                to="/"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Home
              </Link>
              <Link
                to="/about"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                About Us
              </Link>
              <div className="relative">
                <button
                  onClick={handleDropdownToggle}
                  className="text-white px-3 py-2 rounded-md text-sm font-medium flex items-center hover:text-cyan-400"
                >
                  For Authors
                  <ChevronDownIcon className="w-5 h-5 ml-1" />
                </button>
                {isDropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="absolute z-50 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                  >
                    <Link
                      to="/important-dates"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-cyan-400"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        handleMobileMenuToggle();
                      }}
                    >
                      Important Dates
                    </Link>
                    <Link
                      to="/paper-submission"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-cyan-400"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        handleMobileMenuToggle();
                      }}
                    >
                      Paper Submission
                    </Link>
                  </div>
                )}
              </div>
              <Link
                to="/committee"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Committees
              </Link>
              <Link
                to="/speakers"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Speakers
              </Link>
              <Link
                to="/sponsorship"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Sponsorship
              </Link>
              <Link
                to="/event-details"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Event Details
              </Link>
              <Link
                to="/registration"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Registration
              </Link>
              <Link
                to="/contact"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Contact
              </Link>
              <Link
                to="/announcements"
                className="text-white px-3 py-2 rounded-md text-sm font-medium hover:text-cyan-400"
                onClick={handleMobileMenuToggle}
              >
                Announcements
              </Link>
            </div>
          </div>
        )}
      </nav>

      {/* White strip with marquee effect */}
      <div className="bg-white py-1">
        <div className="overflow-hidden whitespace-nowrap">
          <marquee className="text-red-500 font-semi-bold text-2xl">
          Paper review result announcement before 20th November!
          </marquee>
        </div>
      </div>
    </header>
  );
};

export default HeaderWithLogosAndNavbar;