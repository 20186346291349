import React from 'react';

const PaperSubmission = () => {
  return (
    <div className="p-6">
      <h1 className="text-blue-600 text-4xl font-bold text-center mb-6">Paper Submission</h1>
      <p className="mb-4">
        The Call for Papers for the first International Conference organized by APJ Abdul Kalam Technological University is given below. Faculty members, research scholars, and students of your institution may be encouraged to submit papers.
      </p>
      <h2 className="text-xl font-semibold mb-2">Call For Papers</h2>
      <h3 className="text-lg font-semibold mb-2">International Conference on Emerging Technologies for Intelligent Systems (ETIS2025)</h3>
      <p className="mb-4">
        <strong>Organized by:</strong> APJ Abdul Kalam Technological University<br />
        <strong>Dates:</strong> 7-9 February 2025<br />
        <strong>Venue:</strong> Mar Baselios College of Engineering and Technology, Thiruvananthapuram
      </p>
      <h3 className="text-lg font-semibold mb-2">About ETIS2025:</h3>
      <p className="mb-4">
        ETIS2025 aims to bring together leading academicians, scientists, researchers, and research scholars to exchange and share their experiences. It also provides the premier multidisciplinary forum for researchers, practitioners, and educators to present and discuss the most recent innovations, trends and concerns, practical challenges encountered, and the solutions adopted in the field of Emerging Technologies for Intelligent Systems. The conference is organized by APJ Abdul Kalam Technological University and is technically co-sponsored by IEEE Kerala Section and IEEE Industry Applications Society.
      </p>
      <h3 className="text-lg font-semibold mb-2">Scope:</h3>
      <p className="mb-4">
        Technical Papers are solicited on subjects pertaining to the scope of the conference that includes, but is not limited to, the following topics:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>Artificial Intelligence and Machine Learning</li>
        <li>Advanced Communication Systems</li>
        <li>IoT Innovations and Smart Technologies</li>
        <li>Robotics and Automation</li>
        <li>Healthcare Technologies</li>
        <li>Cyber Security and Ethical Hacking</li>
        <li>Cloud and Edge Computing Paradigm</li>
        <li>Data Science and Analytics</li>
        <li>Intelligent Systems for Power Conversion</li>
        <li>Intelligent Transportation Electrification Systems</li>
        <li>Smart Grid and Smart Cities</li>
      </ul>
      <h3 className="text-lg font-semibold mb-2">Paper Submission:</h3>
      <p className="mb-4">
        Prospective authors are invited to submit the full paper (maximum 6 pages) in the standard IEEE conference template for review. All presented papers will be submitted to IEEE Xplore for possible publication.
      </p>
      <p className="font-semibold mb-4">
      IEEE conference template Link: <a className="text-blue-600 hover:bg-blue-100 hover:text-blue-800 px-2 py-1 rounded transition duration-300 ease-in-out" target='blank' rel='nooopener noreferrer' href="https://www.ieee.org/conferences/publishing/templates.html">https://www.ieee.org/conferences/publishing/templates.html</a>
        <br/>
        <p> ‎  </p>
        <a href="IEEE conference-template-a4.docx" download="IEEE conference-template-a4.docx" className="text-blue-600 hover:bg-blue-100 hover:text-blue-800 px-2 py-1 rounded transition duration-300 ease-in-out" >
        <button>Download Word Template</button>
        </a>
      
      </p>
      <p className="font-semibold mb-4">
        For paper submission, please log on to: 
        <a 
          href="https://cmt3.research.microsoft.com/ETIS2025/Submission/Index" 
          className="text-blue-600 hover:bg-blue-100 hover:text-blue-800 px-2 py-1 rounded transition duration-300 ease-in-out"
          target="_blank" 
          rel="noopener noreferrer"
        >
          https://cmt3.research.microsoft.com/ETIS2025/Submission/Index
        </a>
      </p>
    </div>
  );
};

export default PaperSubmission;
