import React from 'react';

const Contact = () => {
  return (
    <div className="p-6">
      <h2 className="text-blue-600 text-4xl font-bold text-center mb-6">Contact Us</h2>
      <div className="mb-6 text-center">
        <h3 className="text-xl font-semibold mb-2">Contact Information</h3>
        <p className="mb-2">
          <strong>Email:</strong> <a href="mailto:etis2025@ktu.edu.in" className="text-blue-600 hover:underline">etis2025@ktu.edu.in</a>
        </p>
        <p className="mb-2">
          <strong>Mob:</strong> 9846121223 (Dr. Vinodkumar Jacob, Convenor 
          )
        </p>
        <p className="mb-2">
          <strong>Mob:</strong> 7907475861 (Dr. Venugopal G, General Chair)
        </p>
        <p className="mb-2">
          <strong>Mob:</strong> 9447791946 (Prof. Sanjeev G, Publicity Chair)
        </p>
        <p className="mb-2">
          <strong>Mob:</strong> 9446808243 (Dr. Biju K, Organizing Secretary)
        </p>
      </div>
      <div className="mb-6 text-center">
        <h3 className="text-xl font-semibold mb-2">Address</h3>
        <p>
          APJ Abdul Kalam Technological University<br />
          CET Campus,<br />
          Alathara Rd,<br />
          Ambady Nagar,<br />
          Thiruvananthapuram,<br />
          Kerala 695016
        </p>
      </div>
      <div className="border border-blue-600 rounded-lg shadow-lg overflow-hidden mx-auto w-3/4 sm:w-1/2">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31564.365002130533!2d76.88731165513468!3d8.543414205759552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05beb9c24000bf%3A0x1147a2f2deda7d76!2sAPJ%20Abdul%20Kalam%20Technological%20University!5e0!3m2!1sen!2sin!4v1725043517517!5m2!1sen!2sin"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="APJ Abdul Kalam Technological University"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
