import React from 'react';

const Announcements = () => {
  const announcements = [
    {
      title: "🚀 Join the 𝗖𝗛𝗔𝗧𝗕𝗢𝗧 𝗖𝗛𝗔𝗟𝗟𝗘𝗡𝗚𝗘!",
      date: "Sunday, 9th February 2025",
      time: "09:00 AM",
      venue: "Mar Baselios College of Engineering and Technology, Thiruvananthapuram",
      description:
        "Team up and build a chatbot in just 6 hours! Don’t miss this exciting opportunity to showcase your skills.",
      registrationLink: "https://bit.ly/ChatBot-Challenge",
      contacts: [
        { name: "Dr. Prem Sankar", phone: "+91 83019 14006" },
        { name: "Jasim", phone: "+91 70341 91969" },
      ],
      imageUrl: "/images/poster.jpg",
    },
    // Add more announcements here
  ];

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-blue-600 text-4xl font-bold text-center mb-6">Announcements</h1>

      {/* Map over announcements array to create individual announcement cards */}
      {announcements.map((announcement, index) => (
        <div key={index} className="border rounded-lg p-4 shadow-lg bg-white mb-8">
          <h2 className="text-xl font-semibold text-center mb-4">{announcement.title}</h2>

          {/* Poster image */}
          {announcement.imageUrl && (
            <img
              src={announcement.imageUrl}
              alt={`${announcement.title} Poster`}
              className="w-full h-auto mb-4 rounded"
            />
          )}

          {/* Details */}
          <p className="text-lg font-semibold mb-2">
            Join the CHATBOT Challege at the International Conference on Emerging Technologies for Intelligent Systems (ETIS 2025), organized by APJ Abdul Kalam Technological University!
          </p>
          <p className="mb-2">📅 <strong>Date:</strong> {announcement.date}</p>
          <p className="mb-2">🕘 <strong>Time:</strong> {announcement.time}</p>
          <p className="mb-2">📍 <strong>Venue:</strong> {announcement.venue}</p>

          <p className="mt-4">{announcement.description}</p>

          {/* Registration Link */}
          <p className="mt-4 font-bold">
            🔗 <a href={announcement.registrationLink} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Register now</a>
          </p>

          {/* Contact Details */}
          <div className="mt-4">
            <p className="font-bold">📞 Contact:</p>
            {announcement.contacts.map((contact, i) => (
              <p key={i}>
                {contact.name} ({contact.phone})
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Announcements;