import React from 'react';

const Registration = () => {
  return (
    <div className="p-6">
      <h1 className="text-blue-600 text-4xl font-bold text-center mb-6">Registration</h1>

      {/* Registration Fee Table */}
      <section className="mb-8">
        <h2 className="text-xl text-center font-semibold mb-4">Registration Fee Table</h2>
        <div className="border rounded-lg p-4 shadow-lg bg-white overflow-x-auto">
          <table className="min-w-full text-center">
            <thead>
              <tr className="border-b">
                <th className="p-3 font-semibold">Category</th>
                <th className="p-3 font-semibold">Non-IEEE Member</th>
                <th className="p-3 font-semibold">IEEE Member</th>
              </tr>
            </thead>
            <tbody>
              {/* Participants from India */}
              <tr className="border-b">
                <td className="p-3">Author (Professional) - from India</td>
                <td className="p-3">7000 INR</td>
                <td className="p-3">6000 INR</td>
              </tr>
              <tr className="border-b">
                <td className="p-3">Delegate (Professional) - from India</td>
                <td className="p-3">6000 INR</td>
                <td className="p-3">5000 INR</td>
              </tr>
              <tr className="border-b">
                <td className="p-3">Author (Student) - from India</td>
                <td className="p-3">5000 INR</td>
                <td className="p-3">4000 INR</td>
              </tr>
              <tr className="border-b">
                <td className="p-3">Delegate (Student) - from India</td>
                <td className="p-3">2500 INR</td>
                <td className="p-3">2250 INR</td>
              </tr>
              {/* Participants from Abroad */}
              <tr className="border-b">
                <td className="p-3">Author (Professional) - from Abroad</td>
                <td className="p-3">200 USD</td>
                <td className="p-3">175 USD</td>
              </tr>
              <tr className="border-b">
                <td className="p-3">Delegate (Professional) - from Abroad</td>
                <td className="p-3">175 USD</td>
                <td className="p-3">150 USD</td>
              </tr>
              <tr className="border-b">
                <td className="p-3">Author (Student) - from Abroad</td>
                <td className="p-3">150 USD</td>
                <td className="p-3">125 USD</td>
              </tr>
              <tr>
                <td className="p-3">Delegate (Student) - from Abroad</td>
                <td className="p-3">100 USD</td>
                <td className="p-3">90 USD</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/* Additional Information */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Additional Information</h2>
        <p className="mb-4">The registration fee excludes 18% GST and payment gateway charges.</p>

      </section>

      {/* Additional Page Charges */}
      <section>
        <h2 className="text-xl font-semibold mb-4">Charges for Additional Pages</h2>
        <p className="mb-4">The normal length of papers is 6 pages. Up to 2 additional pages are permitted with the following rates:</p>
        <ul className="list-disc list-inside mb-4">
          <li className="mb-2">500 INR per page for Indian authors</li>
          <li>15 USD per page for foreign authors</li>
        </ul>
      </section>
    </div>
  );
};

export default Registration;