import React from 'react';

const Sponsorship = () => {
  return (
    <div className="p-6">
      <h1 className="text-blue-600 text-4xl font-bold text-center mb-6">Sponsorship</h1>

      <section className="text-center">
        <a
          href="Sponsorship_brochure.pdf" // Replace with the actual link to your brochure
          download
          className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
        >
          Download Sponsorship Brochure
        </a>
      </section>
    </div>
  );
};

export default Sponsorship;