import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

const ImportantDates = () => {
  const dates = [
    { date: 'July 5', event: 'Call for Papers Announcement' },
    { date: 'July 10', event: 'Draft Paper Submission opens' },
    { date: 'October 15', event: 'Draft paper submission closes' },
    { date: 'November 15', event: 'Notification of Acceptance' },
    { date: 'November 25', event: 'Final Camera-ready Paper' },
  ];

  return (
    <div className="p-6 flex flex-col items-center">
      <h1 className="text-blue-600 text-4xl font-bold text-center mb-6">Important Dates</h1>
      <div className="relative flex flex-col items-center w-full">
        {dates.map((item, index) => (
          <div
            key={index}
            className={`flex items-center mb-8 ${index === dates.length - 1 ? 'mb-0' : ''} ${item.date.includes('November') ? 'mr-6' : ''}`}
          >
            {/* Icon container */}
            <div className="relative flex flex-col items-center">
              {/* Calendar Icon */}
              <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-600 text-white relative z-10">
                <FaCalendarAlt />
              </div>
              {/* Vertical Line */}
              {index < dates.length - 1 && (
                <div className="w-px h-10 bg-blue-600 mt-2"></div>
              )}
            </div>
            {/* Text container */}
            <div className="ml-4">
              <p className="font-bold text-lg">{item.date}</p>
              <p>{item.event}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImportantDates;
