import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-blue-950 text-white py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-between">
          {/* Footer Links */}
          <div className="w-full sm:w-1/3 mb-6">
            <h2 className="text-lg font-bold mb-4">Quick Links</h2>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="hover:text-cyan-400">Home</Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-cyan-400">About Us</Link>
              </li>
              <li>
                <Link to="/committee" className="hover:text-cyan-400">Committee</Link>
              </li>
              <li>
                <Link to="/event-details" className="hover:text-cyan-400">Event Details</Link>
              </li>
              <li>
                <Link to="/important-dates" className="hover:text-cyan-400">Important Dates</Link>
              </li>
              <li>
                <Link to="/paper-submission" className="hover:text-cyan-400">Paper Submission</Link>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="w-full sm:w-1/3 mb-6">
            <h2 className="text-lg font-bold mb-4">Contact Us</h2>
            <p className="mb-2">APJ Abdul Kalam Technological University<br/>Trivandrum, Kerala, India</p>
            <p className="mb-2">Email: <a href="mailto:etis2025@ktu.edu.in" className="hover:text-cyan-400">etis2025@ktu.edu.in</a></p>
            <p>Phone: 0471-2598122</p>
          </div>

          {/* Social Media Links */}
          <div className="w-full sm:w-1/3 mb-6">
            <h2 className="text-lg font-bold mb-4">Follow Us</h2>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/apjaktuofficial/" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-400">
                <img src="/images/facebook-icon.svg" alt="Facebook" className="w-6 h-6" />
              </a>
              <a href="https://x.com/apjaktuofficial" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-400">
                <img src="/images/twitter-icon.svg" alt="Twitter" className="w-6 h-6" />
              </a>
              <a href="https://in.linkedin.com/school/apj-abdul-kalam-technological-university/" target="_blank" rel="noopener noreferrer" className="hover:text-cyan-400">
                <img src="/images/linkedin-icon.svg" alt="LinkedIn" className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>
        <div className="border-t border-blue-700 mt-6 pt-4">
          <p className="text-center text-sm">&copy; {new Date().getFullYear()} APJAKTU ETIS 2025. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
